export const POWERED_BY = 'BuildCaptive USA Inc.'
export const BUILD_CAPTIVE_URL = 'https://buildcaptive.com'
export const SITE_TITLE = 'Conkoa.ai'
export const SITE_DESCRIPTION =
  'Conkoa AI is chat bot that will help you with all things construction.'

export const STACK_APP_NAME = 'conkoa-ai'

export const RAG_UPLOAD_TABLE_NAME = 'ragupload'
export const RAG_UPLOAD_COLLECTION_TABLE_NAME = 'rag_upload_collection'

export const MAX_TEAM_SEATS = 10

export const OG_IMAGE_DEFAULT = 'logos/logo-large-white-bg.webp'
export const PROD_SITE_URL = 'https://conkoa.ai'

export const HERO_IMAGE_URL = '/media/macbook-pro-16-mockup.webp'

export const MAX_CHAT_FILE_SIZE = 1024 * 1024 * 10 // 10MB

export const SEVEN_DAYS_IN_SECONDS = 60 * 60 * 24 * 7
export const ONE_HOUR_IN_SECONDS = 60 * 60

export const FROM_EMAIL = 'support@notifications.conkoa.ai'
